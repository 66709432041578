import { FieldState } from 'formstate'
import { format as _formatPhone } from 'phone-fns'
import currency from 'currency.js'
import { format } from 'date-fns'

export const formatMoney = (value: number | string) => {
  // later maybe introduce formating based on locale
  return currency(value, { separator: ' ', decimal: ',' }).format()
}

export const formatToFixedTwo = (value: number | string) => {
  return Number(value).toFixed(2)
}

export const formatFieldToFixedTwo = (field: FieldState<string>) => {
  const value = field.$
  field.value = Number(value).toFixed(1)

  setTimeout(() => {
    field.onChange(formatToFixedTwo(value))
  }, 0.1)
}

export const formatDate = (value: string | number | Date) => {
  return format(value, 'DD.MM.YYYY')
}

export const formatDateMonth = (value: string | number | Date) => {
  return format(value, 'MM/YY')
}

export const formatDateTime = (
  value: string | number | Date,
  options?: { includeSeconds?: boolean }
) => {
  return format(value, `DD.MM.YYYY HH:mm${options?.includeSeconds ? ':ss' : ''}`)
}

export const formatPhone = (value: string) => {
  // const reversed = [...value.replace(' ', '')].reverse().join('')
  // const matches = reversed.match(/.{1,3}/g)

  // if (!matches) return

  // return [matches[3].concat(matches[2]), matches[1], matches[0]].join(' ')

  return _formatPhone('+NNN NNN NNN NNN', value)
}

export const formatZip = (value: string) => {
  const s = value.replace(' ', '')
  return `${s.slice(0, 3)} ${s.slice(3)}`
}
